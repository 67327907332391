import React from "react";
import "./style.css"

function BotReplyText(props){
    const {link}=props
    console.log(props.text)

    if(link){
        return(
            <div className="botReply">
            <img src="botOnline.png" alt="bot_icon" className="botIcon"/>
            <div className="botText">
                <p><a href={link}>{link}</a></p>
            </div>
        </div>)
    }else{
        return(null)
    }
   
}


export default BotReplyText;