import { Card, CardContent, Grid } from "@material-ui/core";
import { MainTitle } from "../DomesticHelpContent/DomesticHelpContentStyles";
import { Title } from "../HomeText/HomeTextStyles";
import {
  FormalImage,
  ImageWrapper,
  Paragraph,
} from "../OurExpertise/OurExpertiseStyles";
import { ContentWrapper } from "./EnTekVennStyles";
import formal from "../../images/image_6487327.JPG";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { entekVennData } from "../../dummyData";

function EnTekVennContent() {
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <Grid container spacing={2}>
        <Grid item md={7} sm={12}>
          <div>
            <Title>{t(`entekvenn.hero_title`)}</Title>
            <Paragraph>{t(`entekvenn.paragraph1`)}</Paragraph>
            <Paragraph style={{marginTop:20}}>{t(`entekvenn.paragraph2`)}</Paragraph>
          </div>
        </Grid>
        <Grid item md={5} sm={12}>
          <Card style={{ height: "100%" }}>
            <CardContent>
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/nYyFf-97Qqg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ margin: "50px 0" }}>
        <Grid item md={7}>
          <div className="listItem">
            <Title style={{marginBottom: 10}}>{t(`entekvenn.skills_title`)}</Title>
            {entekVennData.map(title => (
              <li>{t(`entekvenn.skills_list.skill${title.id}`)}</li>
            ))}
            <div style={{ marginTop: 50 }}>
              <Link to="/">{t(`entekvenn.signup_link`)}</Link>
            </div>
          </div>
        </Grid>
        <Grid item md={5}>
          <ImageWrapper>
            <FormalImage src={formal} alt="formal" />
          </ImageWrapper>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}

export default EnTekVennContent;
