function reducer(state,action){
    switch(action.type){
        case "LOAD_FRENCH":
            return(
                {...state,
                    french:action.payload,
                    currentLn:"french"

        })

        case "CLEAR_CHAT":
            return(
                {...state,
                    history:[],
                    currentLn:null
        })
        case "LOAD_LINGALA":
            return(
                {...state,
                    lingala:action.payload,
                    currentLn:"lingala"
        })

        case "LOAD_SWAHILI":
            return(
                {...state,
                    swahili:action.payload,
                    currentLn:"swahili"

        })

        case "UPDATE_HISTORY":
            return(
                {...state,
                    history:[...state.history,action.payload]
        })


        default:
            return{...state}
    }
}


export default reducer