import styled from "styled-components";

export const Content = styled.div`
  padding: 100px 10%;
`;

export const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  color: #565656;
  line-height: 70.75px;
  letter-spacing: 0.02rem;
  margin-bottom: 50px;
`;

export const MainTitle = styled.h4`
  font-size: 25px;
  font-weight: 600;
  color: #565656;
  letter-spacing: 0.02rem;
  margin-bottom: 30px;
`;

export const List = styled.ul`
  list-style: none;
  color: #000;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media screen and (max-width: 592px) {
    justify-content: flex-start;
  }
`;
export const Language = styled.li`
  margin: 10px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;

  &:hover {
    color: #ffcd1a;
  }
`;
export const Paragraph = styled.p`
  color: #565656;
  font-size: 20px;
`;
export const StrongTitle = styled.strong`
  color: #ffcd1a;
`;

export const LanguageSelection = styled.div`
  text-align: left;
`;

export const NoticeBoard = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding-left: 50px;
  background: rgba(255, 255, 255, 0.5);

  @media screen and (max-width: 592px) {
    padding-left: 0px;
    text-align: left;
  }
`;
export const Notice = styled.div`
  margin-top: 40px;
  padding: 20px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.05);
`;
export const Heading = styled.h3`
  color: #565656;
  font-size: 25px;
  font-weight: 600;
  color: #565656;
  letter-spacing: 0.02rem;
  margin-bottom: 20px;
`;
export const Help = styled.p`
  color: #565656;
  font-size: 16px;
`;

export const VideoSection = styled.div`
  margin-top: 70px;
  padding-bottom: 50px;
  border-bottom: 2px solid #eeee;
`;
