import { Grid } from "@material-ui/core";
import GuideLinkContent2 from "../GuideLinkContent2/GuideLinkContent2.jsx";

import {
  GuideInfoContainer
} from "./GuideInfoStyles.js";
function GuideInfo2() {
  return (
    <GuideInfoContainer>
      <Grid container spacing={4}>
        <Grid item md={12} sm={12}>
          <GuideLinkContent2 />
        </Grid>
      </Grid>
    </GuideInfoContainer>
  );
}

export default GuideInfo2;
