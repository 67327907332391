import HomeContent from "../../components/HomeContent/HomeContent";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Footer from "../../components/Footer/Footer";
import ChatWidget from "../../bot/components/chatWidget";
import { useTranslation } from "react-i18next";
import EnTekVennContent from "../../components/EnTekVennContent/EnTekVennContent";

function EnTekVenn() {
  const { t } = useTranslation();
  return (
    <>
      <ChatWidget />
      <HomeContent title={t(`entekvenn.hero_title`)}/>
      <EnTekVennContent />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default EnTekVenn;
