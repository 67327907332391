import React from "react";
import {
  TopbarContainer,
  BrandLogo,
  NavbarContainer,
  Bar,
} from "./TopbarStyles";
import brand from "../../images/10.png";
import { motion } from "framer-motion";
import Fade from "react-reveal/Fade";
import { makeStyles } from "@material-ui/styles";
import { NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const useStyles = makeStyles((theme) => ({
  linkStyles: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#000",
    textDecoration: "none",
    margin: "0 10px",
    cursor: "pointer",

    "&:hover": {
      color: "#fff",
      transition: "0.3s ease-in-out",
    },
  },
}));

const Topbar = ({ toggle }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <TopbarContainer>
        <Fade top duration={5000}>
          <NavHashLink
            to="/#home"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
            style={{ margin: 0 }}
          >
            <BrandLogo
              as={motion.img}
              whileHover={{ scale: 0.8 }}
              src={brand}
              alt="brandLogo"
            />
          </NavHashLink>
        </Fade>
        <Bar onClick={toggle} />
        <NavbarContainer>
          <NavHashLink
            to="/#home"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
          >
            {t("menu.home")}
          </NavHashLink>
          <NavHashLink
            to="/#about_us"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
          >
            {t("menu.who_are_we")}
          </NavHashLink>
          <NavHashLink
            to="/#footer"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
          >
            {t("menu.contact")}
          </NavHashLink>
          <NavHashLink
            to="/#vision"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
          >
            {t("menu.vision")}
          </NavHashLink>
          <LanguageSelector style={{textTransform: "capitalize", marginRight: "10px"}}/>
        </NavbarContainer>
      </TopbarContainer>
    </>
  );
};

export default Topbar;
