import { useContext, useState } from "react";
import { motion } from "framer-motion";
import ChatBotContext from "../context/context";
function RenderChatWidget({ toggle }) {
  const chatBotContext = useContext(ChatBotContext);
  return (
    <motion.div
      animate={{ y: [0, 100, 0] }}
      transition={{
        ease: "easeInOut",
        duration: 2,
        repeat: Infinity,
      }}
      className="botIcon"
    >
      <div className="chatOption">
        <ul className="list" onClick={toggle}>
          <li
            onClick={() => {
              chatBotContext.LoadData("French");
            }}
          >
            Bon jour!
          </li>
          <li
            onClick={() => {
              chatBotContext.LoadData("Lingala");
            }}
          >
            Jambo
          </li>
          <li
            onClick={() => {
              chatBotContext.LoadData("Swahili");
            }}
          >
            Mibote!
          </li>
        </ul>
        <div className="arrow"></div>
      </div>
      <img src="./botOnline.png" alt="botIcon" />
    </motion.div>
  );
}

export default RenderChatWidget;
