import React, { useEffect } from "react";
import HomeContent from "../../components/HomeContent/HomeContent";
import CanDo from "../../components/CanDo/CanDo";
import AboutUs from "../../components/AboutUs/AboutUs";
import { HomeContainer } from "./HomeStyles";
import OurExpertise from "../../components/OurExpertise/OurExpertise";
import Testimonial from "../../components/Testimonial/Testimonial";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Footer from "../../components/Footer/Footer";
import ChatWidget from "../../bot/components/chatWidget";
import { useTranslation } from "react-i18next";
const Home = () => {
  const { t } = useTranslation();
  return (
    <HomeContainer id="home">
      <ChatWidget />
      <HomeContent title={t("hero_title")} subTitle={t("hero_subtitle")} />
      <CanDo />
      <AboutUs />
      <OurExpertise />
      <Testimonial />
      <NewsLetter />
      <Footer />
    </HomeContainer>
  );
};

export default Home;
