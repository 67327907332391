import styled from "styled-components";
import { FaBars } from "react-icons/fa";

export const TopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10%;
  z-index: 10;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;
export const BrandLogo = styled.img`
  width: 80px;
  object-fit:contain;

  @media screen and (max-width: 568px) {
    width: 60px;
  }
`;

export const Item = styled.li`
  color: #565656;
  cursor: pointer;
  padding: 5px 25px;

  &:hover {
    color: white;
    background: #ffcd1a;
    transition: all 0.3s ease-in-out;
  }
`;

export const Bar = styled(FaBars)`
  display: none;
  color: #fff;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 2rem;
    cursor: pointer;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
