import React from "react";
import {
  NewsLetterContainer,
  Title,
  Grid3,
  Image,
  ContentWrapper,
} from "./NewsLetterStyles";
import { motion } from "framer-motion";
import Fade from "react-reveal/Fade";
import {useTranslation} from 'react-i18next'

const NewsLetter = () => {
  const { t } = useTranslation()
  return (
    <NewsLetterContainer>
      <ContentWrapper>
          <Title>{t('homeContents.newsletter')}</Title>
        <Grid3>
          <Image
            src="../logo.png"
            as={motion.img}
            whileHover={{ scale: [1, 1.1, 1, 1.1, 1, 1.1, 1] }}
          />
          <Image
            src="../logo1.png"
            as={motion.img}
            whileHover={{ scale: [1, 1.1, 1, 1.1, 1, 1.1, 1] }}
          />
          <Image
            src="../logo2.png"
            as={motion.img}
            whileHover={{ scale: [1, 1.1, 1, 1.1, 1, 1.1, 1] }}
          />
           <Image
            src="../bufdir.png"
            as={motion.img}
            whileHover={{ scale: [1, 1.1, 1, 1.1, 1, 1.1, 1] }}
          />
        </Grid3>
      </ContentWrapper>
    </NewsLetterContainer>
  );
};

export default NewsLetter;
