import React from "react";
import {
  AboutUSContainer,
  Title,
  RoundShape,
  SectionHeading,
  Paragraph,
  Teacher,
  OverlaySection,
  RoundOverlay,
  SmallShape,
  LeftOverlay,
  RightOverlay,
} from "./AboutUsStyles";
import { Grid } from "@material-ui/core";
import round from "../../images/8.png";
import teacher from "../../images/image_6487327.JPG";
import leftOverlay from "../../images/7.png";
import rightOverlay from "../../images/6.png";
import roundShape from "../../images/8.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <AboutUSContainer id="vision">
      <Grid container alignItems="center" spacing={4}>
        <Grid item md={6} sm={12}>
          <SectionHeading>
            <RoundShape
              src={round}
              alt="round"
              as={motion.img}
              animate={{ y: [0, 15, 0] }}
              transition={{
                ease: "easeInOut",
                duration: 2,
                repeat: Infinity,
              }}
            />
            <Title
              as={motion.h2}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ease: "easeInOut",
                repeat: Infinity,
                duration: 3,
                repeatType: "reverse",
              }}
            >
              {t("homeContents.about_us_title")}
            </Title>
          </SectionHeading>
          <RoundOverlay>
            <Paragraph>
             {t("homeContents.about_us_desc")}
            </Paragraph>

            <SmallShape src={roundShape} alt="roundShape" />
          </RoundOverlay>
        </Grid>
        <Grid item md={6} sm={12}>
          <OverlaySection>
            <LeftOverlay src={leftOverlay} alt="leftOverlay" />
            <RightOverlay src={rightOverlay} alt="rightOverlay" />
            <Teacher
              src={teacher}
              alt="video"
              as={motion.img}
              whileHover={{ scale: 0.9 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            />
          </OverlaySection>
        </Grid>
      </Grid>
    </AboutUSContainer>
  );
};

export default AboutUs;
