const data = {
  french: [
    {
      node: "start",
      type: "text",
      text: "Thank you for choosing French as your language.",
      buttons: [
        {
          text: "Domestic Violence",
          nextNode: "domestic_violence",
        },
        {
          text: "Information about Covnode-19)",
          nextNode: "covid_info",
        }

      ],
    },
   
   
    {
      node: "domestic_violence",
      type: "text",
      text: "Guidance and support",
      buttons: [
        {
          text: "Do you suspect violence against children?",
          nextNode: "children_violence",
        },
        {
          text: "Are you being abused or do you know someone who is?",
          nextNode: "beign_abused",
        },
        {
          text: "Forced marriage, genital mutilation and strict social control",
          nextNode: "force_marriage",
        },
        {
          text: "Violence against the elderly",
          nextNode: "ederly_violence",
        },
        {
          text: "Victim of violence with a residence permit",
          nextNode: "violence_residenc",
        },
        {
          text: "Are you exposing someone to violence and threats?",
          nextNode: "exposing_someone",
        },
      ],
    },
    {
      node: "children_violence",
      htmlRender: true,
      buttons: [
        {
          text: "Main Menu",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        },
      ],
      type: "text",
      text:`<ul class="sc-dVNjXY dszKqP"><li class="sc-jHkVzv krYbVP">If you are in serious danger: call 112</li><li class="sc-jHkVzv krYbVP">Do you want to talk to the police? If you are not in acute danger: Call 02800.</li><li class="sc-jHkVzv krYbVP">If you don't want to talk to the police, you should talk to your GP, home care nurse, or someone else you trust.</li><li class="sc-jHkVzv krYbVP"><a href="https://bekymringsmelding.fiks.ks.no/" target="_blank" class="sc-bQtKYq cZiARS">Report to the child protection service (bufdir.no)</a></li><li class="sc-jHkVzv krYbVP"><a href="https://www.116111.no/" target="_blank" class="sc-bQtKYq cZiARS">Alarm telephone for children and adolescents: 116 111</a></li><li class="sc-jHkVzv krYbVP"><a href="https://www.bufdir.no/vold/Vold_og_overgrep_mot_barn_og_unge/Mistenker_du_at_et_barn_er_utsatt_for_vold/" target="_blank" class="sc-bQtKYq cZiARS">You think a child is exposed to violence (bufdir.no)</a></li><li class="sc-jHkVzv krYbVP"><a href="https://barnevernvakten.no/" target="_blank" class="sc-bQtKYq cZiARS">Find your local child protection officer (barnevernvakten.no)</a></li><li class="sc-jHkVzv krYbVP"><a href="https://min.helsekompetanse.no/course/view.php?id=149" target="_blank" class="sc-bQtKYq cZiARS">Course on violence and abuse against children (helsekompetanse.no)</a></li></ul>`
    },
    {
      node: "beign_abused",
      htmlRender: true,
      buttons: [
        {
          text: "Menu principal",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
        
      ],
      type: "text",
      text: `<ul class="sc-kYHfwS jVfBuM"><li class="sc-xiLah bjisdg">Le conseil le plus important est de contacter la police. Ils ont l'expertise pour trouver les mesures de protection qui vous conviennent.</li><li class="sc-xiLah bjisdg">Connaître le numéro de téléphone de la police 02800</li></ul>`,
    },
    {
      node: "force_marriage",
      htmlRender: true,
      buttons: [
        {
          text: "Menu principal",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
      type: "text",
      text: `<ul class="sc-kYHfwS jVfBuM"><li class="sc-xiLah bjisdg">Si vous êtes en grave danger : appelez le 112</li><li class="sc-xiLah bjisdg">Voulez-vous parler à la police? Si vous n'êtes pas en danger aigu : Appelez le 02800.</li><li class="sc-xiLah bjisdg">Si vous ne voulez pas parler à la police, vous devriez en parler à votre médecin généraliste, à une infirmière de soins à domicile ou à une autre personne en qui vous avez confiance.</li></ul>`,
    },
    {
      node: "ederly_violence",
      htmlRender: true,
      buttons: [
        {
          text: "Menu principal",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
      type: "text",
      text: `<ul class="sc-kYHfwS jVfBuM"><li class="sc-xiLah bjisdg">Si vous êtes en grave danger : appelez le 112</li><li class="sc-xiLah bjisdg">Voulez-vous parler à la police? Si vous n'êtes pas en danger aigu : Appelez le 02800</li><li class="sc-xiLah bjisdg">Si vous ne voulez pas parler à la police, vous devriez en parler à votre médecin généraliste, à une infirmière de soins à domicile ou à une autre personne en qui vous avez confiance.</li></ul>`,
    },
    {
      node: "violence_residenc",
      htmlRender: true,
      buttons: [
        {
          text: "Menu principal",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
      type: "text",
      text: `<ul class="sc-kYHfwS jVfBuM"><li class="sc-xiLah bjisdg">Si vous êtes en grave danger : appelez le 112</li><li class="sc-xiLah bjisdg">Voulez-vous parler à la police? Si vous n'êtes pas en danger aigu : Appelez le 02800.</li><li class="sc-xiLah bjisdg">Si vous ne voulez pas parler à la police, vous devriez en parler à votre médecin généraliste, à une infirmière de soins à domicile ou à une autre personne en qui vous avez confiance.</li><li class="sc-xiLah bjisdg"><a href="https://bekymringsmelding.fiks.ks.no/" target="_blank" class="sc-dVNjXY cKGlcd">Signalement au service de protection de l'enfance (bufdir.no)</a></li><li class="sc-xiLah bjisdg"><a href="https://www.116111.no/" target="_blank" class="sc-dVNjXY cKGlcd">Téléphone d'alarme pour enfants et adolescents : 116 111</a></li><li class="sc-xiLah bjisdg"><a href="https://www.bufdir.no/vold/Vold_og_overgrep_mot_barn_og_unge/Mistenker_du_at_et_barn_er_utsatt_for_vold/" target="_blank" class="sc-dVNjXY cKGlcd">Vous pensez qu'un enfant est exposé à la violence (bufdir.no)</a></li><li class="sc-xiLah bjisdg"><a href="https://barnevernvakten.no/" target="_blank" class="sc-dVNjXY cKGlcd">Trouvez votre agent local de protection de l'enfance (barnevernvakten.no)</a></li><li class="sc-xiLah bjisdg"><a href="https://min.helsekompetanse.no/course/view.php?node=149" target="_blank" class="sc-dVNjXY cKGlcd">Cours sur la violence et les abus contre les enfants (helsekompetanse.no)</a></li></ul>`,
    },
    {
      node: "exposing_someone",
      htmlRender: true,
      buttons: [
        {
          text: "Menu principal",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
      type: "text",
      text:`<ul class="sc-kYHfwS jVfBuM"><li class="sc-xiLah bjisdg"><a href="https://dinutvei.no/hjelpetilbud" target="_blank" class="sc-dVNjXY cKGlcd">Offre d'anodee sur Dinutvei.no</a></li><li class="sc-xiLah bjisdg"><a href="http://atv-stiftelsen.no/" target="_blank" class="sc-dVNjXY cKGlcd">Alternative à la violence</a></li><li class="sc-xiLah bjisdg"><a href="https://www.bufdir.no/Familie/Finn_familievernkonhrefr/" target="_blank" class="sc-dVNjXY cKGlcd">Les offices de protection de la famille</a></li><li class="sc-xiLah bjisdg"><a href="https://reform.no/" target="_blank" class="sc-dVNjXY cKGlcd">Reform - centre de ressources pour hommes</a></li><li class="sc-xiLah bjisdg"><a href="http://tryggere.no/" target="_blank" class="sc-dVNjXY cKGlcd">La Fondation Safer</a></li><li class="sc-xiLah bjisdg"><a href="http://www.sinnemestring.no/" target="_blank" class="sc-dVNjXY cKGlcd">Gestion de la colère</a></li><li class="sc-xiLah bjisdg"><a href="http://www.littsint.no/" target="_blank" class="sc-dVNjXY cKGlcd">Gestion de la colère des parents</a></li></ul>`
    },
    // Covid Information

    {
      node: "covid_info",
      type: "text",
      text: "Guidance and support",
      buttons: [
        {
          text: "Recent news",
          nextNode: "covid_recent_news",
        },
        {
          text: "Symptoms",
          nextNode: "covid_symptoms",
        },
        {
          text: "Quarantine and separation",
          nextNode: "quarantine_separation",
        },
        {
          text: "Economic Assistance",
          nextNode: "economic_assistanc",
        }
      ],
    },
    {
      node: "covid_recent_news",
      type: "gallery",
      gallery: [
        {
          title:
            "Smittevernråd for reise og innreisekarantene ved ankomst til Norge",
          link: "https://www.fhi.no/en/node/infectious-diseases/coronavirus/",
          imageURL:
            "https://www.fhi.no/globalassets/dokumenterfiler/tema/koronavirussykdom/2871--cdc-alissa-eckert-ms_modifisert.png?preset=themeheaderimage",
          visitNow: "Tazama sasa",
        },
      ],
      buttons: [
        {
          text: "Zanodei",
          nextNode: 3,
        },
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
    },
    {
      node: "3",
      type: "gallery",
      gallery: [
        {
          title: "Status koronaviruset",
          link: "https://www.nrk.no/korona/status/",
          imageURL:
            "https://www.fhi.no/globalassets/dokumenterfiler/tema/koronavirussykdom/2871--cdc-alissa-eckert-ms_modifisert.png?preset=themeheaderimage",
          visitNow: "Tazama sasa",
        },
      ],
      buttons: [
        {
          text: "Zanodei",
          nextNode: "start",
        },
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
    },
    {
      node: "covid_symptoms",
      type: "link",
      link: "https://helsenorge.no/koronavirus/smitte-og-inkubasjonstnode",
      buttons: [
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
    },
    {
      node: "quarantine_separation",
      type: "link",
      link: "https://helsenorge.no/koronavirus/karantene-og-isolasjon",
      buttons: [
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
        {
          text: "Live Chat",
          nextNode: "live_chat",
        }
      ],
    },



    {
      node: "economic_assistanc",
      type: "text",
      text: "Kazi yako ni nini",
      buttons: [
        {
          text: "Mwanafunzi",
          nextNode: 7,
        },
        {
          text: "Mfanyakazi",
          nextNode: 8,
        },
        {
          text: "Mfanyabiashara",
          nextNode: 9,
        },
      ],
    },



    





  ],


  swahili: [
    {
      node: 1,
      type: "text",
      text: "Ba information na Lingala ",
      buttons: [
        {
          text: "Habari za hivi karibuni",
          nextNode: 2,
        },
        {
          text: "Dalili",
          nextNode: 4,
        },
        {
          text: "Karantini na kujitenga",
          nextNode: 5,
        },
        {
          text: "Msaada wa Kiuchumi",
          nextNode: 6,
        },
      ],
    },
    {
      node: 2,
      type: "gallery",
      gallery: [
        {
          title:
            "Smittevernråd for reise og innreisekarantene ved ankomst til Norge",
          link: "https://www.fhi.no/en/node/infectious-diseases/coronavirus/",
          imageURL:
            "https://www.fhi.no/globalassets/dokumenterfiler/tema/koronavirussykdom/2871--cdc-alissa-eckert-ms_modifisert.png?preset=themeheaderimage",
          visitNow: "Tazama sasa",
        },
      ],
      buttons: [
        {
          text: "Zanodei",
          nextNode: "zanodei",
        },
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
      ],
    },
    {
      node: "zanodei",
      type: "gallery",
      gallery: [
        {
          title: "Status koronaviruset",
          link: "https://www.nrk.no/korona/status/",
          imageURL:
            "https://www.fhi.no/globalassets/dokumenterfiler/tema/koronavirussykdom/2871--cdc-alissa-eckert-ms_modifisert.png?preset=themeheaderimage",
          visitNow: "Tazama sasa",
        },
      ],
      buttons: [
        {
          text: "Zanodei",
          nextNode: "start",
        },
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
      ],
    },
    {
      node: 4,
      type: "link",
      link: "https://helsenorge.no/koronavirus/smitte-og-inkubasjonstnode",
      buttons: [
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
      ],
    },
    {
      node: 5,
      type: "link",
      link: "https://helsenorge.no/koronavirus/karantene-og-isolasjon",
      buttons: [
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
      ],
    },
    {
      node: 6,
      type: "text",
      text: "Kazi yako ni nini",
      buttons: [
        {
          text: "Mwanafunzi",
          nextNode: 7,
        },
        {
          text: "Mfanyakazi",
          nextNode: 8,
        },
        {
          text: "Mfanyabiashara",
          nextNode: 9,
        },
      ],
    },
    {
      node: 7,
      type: "link",
      link: "https://www.lanekassen.no/en-US/presse-og-samfunnskontakt/nyheter/forslag-til-nye-koronatiltak-i-2021/",
      buttons: [
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
      ],
    },
    {
      node: 8,
      type: "link",
      link: "https://www.nav.no/person/koronaveiviser/",
      buttons: [
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
      ],
    },
    {
      node: 9,
      type: "link",
      link: "https://kompensasjonsordning.no/",
      buttons: [
        {
          text: "Menyu kuu",
          nextNode: "start",
        },
      ],
    },
  ],
};

export default data;
