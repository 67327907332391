import React from "react";
import "./style.css"
import Card from "../card/"

function Gallery(props){
    const {Blog}=props
    console.log(Blog)
    return(
    <div className="botReply">
    <img src="botOnline.png" alt="bot_icon" className="botIcon"/>
    <div className="gallery">
        <Card data={Blog}/>
    </div>
</div>)
}


export default Gallery;