import React from "react";
import { Grid } from "@material-ui/core";
import {
  CanDoContainer,
  CanDoTitle,
  StrongTitle,
  Content,
  Title,
  Dash,
  Description,
  Overlay,
  ContentWrapper,
  BGOverlay,
} from "./CanDoStyles";
import { images } from "../../dummyData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Zoom from "react-reveal/Zoom";
import { useTranslation } from "react-i18next";

const CanDo = () => {
  const {t} =useTranslation()
  return (
    <CanDoContainer>
      <Zoom left duration={2000}>
        <CanDoTitle>
          {t('homeContents.can_do_title')}
        </CanDoTitle>
      </Zoom>
      <Grid container spacing={5} justifyContent="center">
        {images.map((image) => (
          <Grid item lg={3} md={4} sm={6} xs={12} key={image.id}>
            <Link to={image.link} style={{ textDecoration: "none" }}>
              <ContentWrapper
                as={motion.div}
                whileHover={{ scale: 1.02, y: [0, -30, 0] }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <Content
                  style={{
                    backgroundImage: `url(${image.img})`,
                    backgroundSize: "cover",
                  }}
                >
                  <BGOverlay />
                  <Title>{t(`homeContents.card_contents.card${image.id}_title`)}</Title>
                  <Dash></Dash>
                  <Description>{t(`homeContents.card_contents.card${image.id}_desc`)}</Description>
                </Content>
                <Overlay />
              </ContentWrapper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </CanDoContainer>
  );
};

export default CanDo;
