import styled from "styled-components";
export const ContentContainer = styled.div`
  padding: 20px 0px;
`;
export const CardOne = styled.div`
  margin: 0px;
`;
export const CardTwo = styled.div``;
export const CardThree = styled.div``;
export const CardFour = styled.div``;
export const CardFive = styled.div``;
export const CardSix = styled.div``;

export const Title = styled.h2`
  font-size: 25px;
  font-weight: 600;
  color: #565656;
  letter-spacing: 0.02rem;
  margin-bottom: 20px;
`;

export const SubTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #565656;
  letter-spacing: 0.02rem;
  margin: 16px 0;
`;
export const MainContent = styled.div`
  margin-top: 20px;
  border-top: 1px solid lightgray;
`;
export const List = styled.ul`
  margin-left: 20px;
`;
export const Item = styled.li`
  margin: 10px 0;
  padding: 0;
  font-size: 18px;
  color: #565656;
`;
export const NavLink = styled.a`
  text-decoration: none;
  color: blue;
`;
