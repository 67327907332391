import { Grid } from "@material-ui/core";
import GuideLinkContent from "../GuideLinkContent/GuideLinkContent.jsx";

import {
  GuideInfoContainer
} from "./GuideInfoStyles.js";
function GuideInfo() {
  return (
    <GuideInfoContainer>
      <Grid container spacing={4}>
        <Grid item md={12} sm={12}>
          <GuideLinkContent />
        </Grid>
      </Grid>
    </GuideInfoContainer>
  );
}

export default GuideInfo;
