import { Card, CardContent, Grid } from "@material-ui/core";
import GuideInfo from "../../../../components/GuideInfo/GuideInfo";
import {
  Content,
  LanguageSelection,
  MainTitle,
  Paragraph,
  List,
  Language,
  NoticeBoard,
  Notice,
  Heading,
  Help,
  VideoSection,
} from "./DomesticHelpContentStyles";
import { motion } from "framer-motion";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import { languages } from "../../../../dummyData";
import i18next from "i18next";
import { Title } from "../../../Home/HomeStyles";
import GuideInfo2 from "../GuideInfo2/GuideInfo2";

function DomesticHelp2Content() {
  const { t } = useTranslation();
  return (
    <Content>
      <LanguageSelection>
        <div style={{textAlign:"center", marginBottom:60}}>
          <Title>{t("strong_notice2")}</Title>
        </div>
        <Grid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
            {/* <MainTitle>{t("domestic_help.title")}</MainTitle>
            <Paragraph>{t("domestic_help.desc")}</Paragraph> */}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List>
              {languages.map((lang) => (
                <Language
                  as={motion.li}
                  whileHover={{
                    scale: 1.1,
                    originX: 0,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                  }}
                  key={lang.id}
                  onClick={() => i18next.changeLanguage(lang.code)}
                >
                  {lang.name}
                </Language>
              ))}
            </List>
            <NoticeBoard>
              <Notice>
                <Heading>{t("noticeBoard.title")}</Heading>
                {/* <Help>{t("noticeBoard.notice")}</Help> */}
              </Notice>
            </NoticeBoard>
          </Grid>
        </Grid>
      </LanguageSelection>
      <GuideInfo2 />
    </Content>
  );
}

export default DomesticHelp2Content;
