import styled from "styled-components";
export const LinkContainer = styled.div`
  margin: 50px 0;
  text-align: center;
`;
export const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  color: #565656;
  line-height: 70.75px;
  letter-spacing: 0.02rem;
  margin-bottom: 50px;

  @media screen and (max-width: 592px) {
    font-size: 30px;
    line-height: 50.75px;
  }
`;

export const StrongTitle = styled.strong`
  color: #ffcd1a;
`;

export const CardTitle = styled.h4`
  font-size: 18px;
  font-weight: 600;
  color: #565656;
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: #565656;
`;
export const Dash = styled.div`
  width: 60px;
  border: 2px solid #c0c3c9;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 25px;
`;
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom:10px;
`;

export const ContentWrapper = styled.div`
  padding: 18px;
  height: 100%;
  text-align: left;
  background: #f4f4f4;
  cursor: pointer;

  &:hover {
    background: #4b5362;
    color: white;
    transition: all 0.5s ease-in-out;
  }
  &:hover ${ImageWrapper} {
    background: #f4f4f4;
   padding: 5px;
  }
  &:hover ${CardTitle} {
    color: white;
  }
  &:hover ${Paragraph} {
    color: white;
  }
  &:hover ${Dash} {
    border: 2px solid white;
  }
`;

export const CardImage = styled.img`
  object-fit: contain;
`;
