import React from "react";
import { Grid } from "@material-ui/core";
import {
  OurExpertiseContainer,
  LeftContent,
  Title,
  Dash,
  Paragraph,
  RightContent,
  FormalImage,
  ImageWrapper,
} from "./OurExpertiseStyles";
import formal from "../../images/about_us.jpg";
import { useTranslation } from "react-i18next";

const OurExpertise = () => {
  const { t } = useTranslation();

  return (
    <OurExpertiseContainer id="about_us">
      <Grid container spacing={4} direction="row" alignItems="center">
        <Grid item md={6} sm={12} xs={12}>
          <LeftContent>
            <Title>{t("homeContents.expertise_title")}</Title>
            <Dash />
            <Paragraph>{t("homeContents.expertise_desc")}</Paragraph>
          </LeftContent>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <RightContent>
            <ImageWrapper>
              <FormalImage src={formal} alt="formal" />
            </ImageWrapper>
          </RightContent>
        </Grid>
      </Grid>
    </OurExpertiseContainer>
  );
};

export default OurExpertise;
