import { ThemeProvider } from "@material-ui/styles";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout.jsx";
import Home from "./pages/Home/Home.jsx";
import DomesticHelp from "./pages/DomesticHelp/DomesticHelp.jsx";
import theme from "./presentation/mui-theme.js";
import ChatbotState from "./bot/context/state";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.jsx";
import EnTekVenn from "./pages/EnTekVenn/EnTekVenn.jsx";
import DomesticHelp2 from "./pages/DomesticHelp2/DomesticHelp2.jsx";
function App() {
  return (
    <Layout>
      <ChatbotState>
        <ThemeProvider theme={theme}>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/domestic-help" element={<DomesticHelp />} />
              <Route path="/domestic-help2" element={<DomesticHelp2 />} />
              <Route path="/entekvenn" element={<EnTekVenn />} />
            </Routes>
          </ScrollToTop>
        </ThemeProvider>
      </ChatbotState>
    </Layout>
  );
}

export default App;
