import React from "react";
import "./style.css"

function BotReplyText(props){
    const {text,htmlRender}=props

    if(htmlRender){
        return(
            <div className="botReply">
            <img src="botOnline.png" alt="bot_icon" className="botIcon"/>
            <div className="botText">
                <p dangerouslySetInnerHTML={{__html:text}}></p>
            </div>
        </div>)
    }

    return(
    <div className="botReply">
    <img src="botOnline.png" alt="bot_icon" className="botIcon"/>
    <div className="botText">
        <p>{text}</p>
    </div>
</div>)
}


export default BotReplyText;