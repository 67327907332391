import React,{Fragment,useEffect} from "react";
import BotReplyText from "../components/response/botResponse"
import UserReply from "../components/response/userReply"
import Gallery from "../components/response/gallery"
import Button from "../components/response/button";
import BotLink from "../components/response/botLink"


function RenderComponent(props){
    let {type,text,buttons,gallery,link,htmlRender}=props.data

    useEffect(()=>{
        props.scroll()
    },[])

    if(type==="text" && buttons!==undefined){
        return(<Fragment>
                <BotReplyText text={text} htmlRender={htmlRender} />
                   <Button data={buttons}/>
               </Fragment>)
    }else if(type==="text" && buttons===undefined){
        return(<Fragment>
                <BotReplyText text={text} htmlRender={htmlRender}/>
               </Fragment>)
    }else if(type==="gallery" &&buttons===undefined){
        return(<Gallery Blog={gallery[0]}/>)
    }else if(type==="gallery" && buttons.length!==0){
        return(<Fragment>
            <Gallery Blog={gallery[0]}/>
            <Button data={buttons}/>
        </Fragment>)
    }else if(type="link" && buttons===undefined){
            return(<BotLink link={link}/>)
    }else if(type="link" && buttons!==undefined){
            return(<Fragment>
                <BotLink link={link}/>
                <Button data={buttons}/>
            </Fragment>)
    }
    // }else{
    //     return(<BotReplyText text="...typing"/>)
    // }


    



    
};

export default RenderComponent;



