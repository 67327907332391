import {
  Card,
  CardActionArea,
  CardActions,
  Container,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import ModalContent2 from "../ModalContent2/ModalContent2";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    height: 550,
    overflow: "scroll",
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100vh",
    },
    "&:hover":{
      backgroundColor: "white"
    }
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-end"
  },
  closeBtn: {
    border: "none",
    outline: "none",
    padding: "12px 20px",
    borderRadius: "25px",
    background: "#ffcd1a",
    color: "white",
    cursor: "pointer",

    "&:hover":{
      background:"#4b5362",
      color:  "white"
    }
  }
}));

function ModalContentsWrapper({ open, onClick, id }) {
  const classes = useStyles();
  return (
    <div>
      <Modal open={open}>
        <Container className={classes.container}>
        <ModalContent2 id={id} />
            <CardActions className={classes.buttonHolder}>
            <button  className={classes.closeBtn}>
                Live Chat
              </button>
              <button onClick={onClick} className={classes.closeBtn}>
                Close
              </button>
            </CardActions>
        </Container>
      </Modal>
    </div>
  );
}

export default ModalContentsWrapper;
