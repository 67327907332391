import { Grid } from "@material-ui/core";
import { useState } from "react";
import {
  Title,
  ImageWrapper,
  CardImage,
  LinkContainer,
  ContentWrapper,
  CardTitle,
  Dash,
  Paragraph,
} from "./GuideLinkContentStyles.js";
import ModalContent from "../ModalContentsWrapper/ModalContentsWrapper.jsx";
import { modalData } from "../../dummyData.js";
import { motion } from "framer-motion";
import Zoom from "react-reveal/Zoom";
import { useTranslation } from "react-i18next";

function GuideLinkContent() {
  const [open, setOpen] = useState(false);
  const [singleId, setSingleId] = useState();
  const { t } = useTranslation();

  const handleSingleClick = (id) => {
    setSingleId(id);
  };

  return (
    <LinkContainer>
      <Zoom left duration={2000}>
        <Title>{t("guidanceSupport.title")}</Title>
      </Zoom>
      <Grid container spacing={4}>
        {modalData.map((data) => (
          <Grid
            item
            md={4}
            sm={6}
            key={data.id}
            onClick={() => {
              setOpen(!open);
              handleSingleClick(data.id);
            }}
          >
            <ContentWrapper as={motion.div} whileHover={{ scale: 1.1 }}>
              <ImageWrapper>
                <CardImage src={data.img} alt="vector" />
              </ImageWrapper>
              <CardTitle>
                {t(`guidanceSupport.card_contents.card${data.id}_title`)}
              </CardTitle>
              <Dash></Dash>
              <Paragraph>
                {t(`guidanceSupport.card_contents.card${data.id}_desc`)}
              </Paragraph>
            </ContentWrapper>
          </Grid>
        ))}
        <ModalContent
          open={open}
          id={singleId}
          onClick={() => setOpen(!open)}
        />
      </Grid>
    </LinkContainer>
  );
}

export default GuideLinkContent;
