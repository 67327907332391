import React,{useContext,useRef} from "react";
import "./index.css"
import ChatBotContext from "../context/context"
import RenderComponent from "./renderResponse"
import BotReplyText from "../components/response/botResponse"
import { useTranslation} from 'react-i18next';

function Chat(){
    const chatBotContext=useContext(ChatBotContext);
    const messagesEndRef = useRef();
    const {history}=chatBotContext.state;
    const { t } = useTranslation();


    const Scroll=()=>{
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth',block:"end"});
    }


    return(<div className="chat_screen">
        <div className="chat_history" id="chat_history" >
            {
                history.map((item,key)=>{
                    return(<RenderComponent ref={messagesEndRef} data={item} key={key} scroll={Scroll}/>)
                })
            }
            <div ref={messagesEndRef}></div>
        </div>

    </div>)
};

export default Chat;



