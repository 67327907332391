import React from "react";
import { Grid } from "@material-ui/core";
import {
  FooterContainer,
  ContactInfo,
  Title,
  Terms,
  AddressBar,
  Address,
  FooterImage,
  FooterTitle,
  ImageWrapper,
  InfoTitle,
} from "./FooterStyles";
import brand from "../../images/10.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  linkStyles: {
    fontSize: "16px",
    lineHeight: "26px",
    textDecoration: "none",
    margin: "8px 0px",
    letterSpacing: "0.02em",
    color: "#5e6282",
    cursor: "pointer",

    "@media screen and (max-width:568px)": {
      margin: "5px 0px",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <FooterContainer id="footer">
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item lg={3} md={6} sm={6} xs={6}>
          <ImageWrapper>
            <NavHashLink
              to="/#home"
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
              className={classes.linkStyles}
            >
              <FooterImage
                src={brand}
                alt="brand"
                style={{ margin: 0 }}
                as={motion.img}
                animate={{ y: [0, 10, 0] }}
                transition={{
                  ease: "easeInOut",
                  duration: 2,
                  repeat: Infinity,
                }}
              />
            </NavHashLink>
          </ImageWrapper>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={6}>
          <ContactInfo>
            <Title>MANDARI</Title>
            <Terms>
              <NavHashLink
                to="/#about_us"
                scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                className={classes.linkStyles}
              >
                {t("menu.who_are_we")}
              </NavHashLink>
              <NavHashLink
                to="/#vision"
                scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                className={classes.linkStyles}
              >
                {t("menu.vision")}
              </NavHashLink>
              <NavHashLink
                to="/"
                className={classes.linkStyles}
                style={{ marginRight: 20 }}
              >
                {t("menu.language")}
              </NavHashLink>
            </Terms>
          </ContactInfo>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={6}>
          <ContactInfo>
            <Title>{t("menu.contact")}</Title>
            <Terms>
              <a
                href="mailto:kontakt@mandari.no"
                style={{ textDecoration: "none" }}
              >
                {t("menu.contact_us")}
              </a>
            </Terms>
          </ContactInfo>
        </Grid>
        <Grid item lg={3} md={6} sm={6} xs={6}>
          <AddressBar>
            <Address>
              <InfoTitle>{t("menu.address")}:</InfoTitle> <br />
              Hafrsfjordgata 29 A, 0268 Oslo
            </Address>
          </AddressBar>
        </Grid>
      </Grid>
      <FooterTitle>{t("copywrite")}</FooterTitle>
    </FooterContainer>
  );
};

export default Footer;
