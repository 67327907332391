import { Card, CardContent, Grid } from "@material-ui/core";
import GuideInfo from "../GuideInfo/GuideInfo";
import {
  Content,
  LanguageSelection,
  MainTitle,
  Paragraph,
  List,
  Language,
  NoticeBoard,
  Notice,
  Heading,
  Help,
  VideoSection,
} from "./DomesticHelpContentStyles";
import { motion } from "framer-motion";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";
import { languages } from "../../dummyData";
import i18next from "i18next";
import { Title } from "../../pages/Home/HomeStyles";

function DomesticHelpContent() {
  const { t } = useTranslation();
  return (
    <Content>
      <LanguageSelection>
        <div style={{textAlign:"center", marginBottom:60}}>
          <Title>{t("strong_notice")}</Title>
        </div>
        <Grid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
            <MainTitle>{t("domestic_help.title")}</MainTitle>
            <Paragraph>{t("domestic_help.desc")}</Paragraph>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <List>
              {languages.map((lang) => (
                <Language
                  as={motion.li}
                  whileHover={{
                    scale: 1.1,
                    originX: 0,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                  }}
                  key={lang.id}
                  onClick={() => i18next.changeLanguage(lang.code)}
                >
                  {lang.name}
                </Language>
              ))}
            </List>
            <NoticeBoard>
              <Notice>
                <Heading>{t("noticeBoard.title")}</Heading>
                <Help>{t("noticeBoard.notice")}</Help>
              </Notice>
            </NoticeBoard>
          </Grid>
        </Grid>
      </LanguageSelection>
      <VideoSection>
        <Grid container spacing={4}>
          <Grid item md={4} sm={6}>
            <motion.div whileHover={{ y: [0, -20, 0] }}>
              <Card>
                <CardContent>
                  <iframe
                    width="100%"
                    height="300px"
                    src="https://www.youtube.com/embed/nYyFf-97Qqg"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <strong>Swahili</strong>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
          <Grid item md={4} sm={6}>
            <motion.div as={motion.div} whileHover={{ y: [0, -20, 0] }}>
              <Card>
                <CardContent>
                  <iframe
                    width="100%"
                    height="300px"
                    src="https://www.youtube.com/embed/zEQiNFAwDGo"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <strong>French</strong>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
          <Grid item md={4} sm={6}>
            <motion.div as={motion.div} whileHover={{ y: [0, -20, 0] }}>
              <Card>
                <CardContent>
                  <iframe
                    width="100%"
                    height="300px"
                    src="https://www.youtube.com/embed/vyJU9efvUtQ"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <strong>Lingala</strong>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        </Grid>
      </VideoSection>
      <GuideInfo />
    </Content>
  );
}

export default DomesticHelpContent;
