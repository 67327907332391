import DomesticHelpContent from "../../components/DomesticHelpContent/DomesticHelpContent";
import HomeContent from "../../components/HomeContent/HomeContent";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Footer from "../../components/Footer/Footer";
import ChatWidget from "../../bot/components/chatWidget";
import { useTranslation } from "react-i18next";

function DomesticHelp() {
  const { t } = useTranslation();
  return (
    <>
      <ChatWidget />
      <HomeContent title={t("hero_title")} subTitle={t("hero_subtitle")} />
      <DomesticHelpContent />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default DomesticHelp;
