import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import i18next from "i18next";
import { useState } from "react";
import { languages } from "../../dummyData";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";

function LanguageSelector({ style }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const useStyles = makeStyles((theme) => ({
    linkStyles: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#000",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
  
      "&:hover": {
        color: "#fff",
        transition: "0.3s ease-in-out",
      },
    },
  }));
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.linkStyles}
      >
       <span style={style}>{t("menu.language")}</span>
        <MdLanguage style={{ fontSize: "22px", color: "#fff" }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.id}
            onClick={() => {
              i18next.changeLanguage(lang.code);
              handleClose();
            }}
          >
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LanguageSelector;
