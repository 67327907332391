import React,{useContext} from "react";
import "./style.css"
import ChatbotContext from "../../context/context"

function Button(props){
    const buttons=props.data
    const chatbotContext = useContext(ChatbotContext);
    return(
    <div className="botReply">
    <div className="gallery buttons">
        {buttons.map((item,key)=>{
               {if(item.action!==undefined){
                   return(<button onClick={()=>{chatbotContext.LoadData(item.text)}}>{item.text}</button>)
               }else{
                return(<button onClick={()=>{chatbotContext.NextNode(item.nextNode)}}>{item.text}</button>)

               }
            }
        })}
    </div>
</div>)
}


export default Button;