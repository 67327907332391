import imgOne from "./images/imgOne.jpg";
import imgTwos from "./images/14.png";
import imgThrees from "./images/odis.jpg";
import imgFours from "./images/imgFour.jpg";
import imgFive from "./images/imgSeven.jpg";
import imgSix from "./images/imgFive.jpg";
import imgSeven from "./images/WhatsApp Image 2021-11-29 at 3.46.25 PM.jpeg";
import imgEight from "./images/WhatsApp Image 2021-11-29 at 3.46.24 PM.jpeg";
import imageSix from "./images/imgSeven.jpg";
import vectorOne from "./images/1d.png";
import vectorTwo from "./images/2d.png";
import vectorThree from "./images/3d.png";
import vectorFour from "./images/4d.png";
import vectorFive from "./images/5d.png";
import vectorSix from "./images/6d.png";

export const testimonials = [
  {
    id: 1,
    img: imgOne,
    text: "TekVenn - Gratis kurs i grunnleggende ferdigheter innen hverdagsteknologi",
  },
  {
    id: 2,
    img: imgTwos,
    text: "5. Husk a dance - Forebygg, brems og håndter demens gjennom yoga og dans",
  },
  {
    id: 3,
    img: imgThrees,
    text: "JAVIKODE - Gratis kurs i kodig og digitale ferdigheter",
  },
  {
    id: 4,
    img: imgFours,
    text: "Spilleadferd - Info/veiledning til ressurspersoner om spilleavhengighet/adferd",
  },
  {
    id: 5,
    img: imgSeven,
    text: "ATG - En helg med de tradisjonelle Afrikanske leker for barn og unge",
  },
  {
    id: 6,
    img: imgEight,
    text: "Husk å Danse - Dans og yoga for senior",
  },
  {
    id: 7,
    img: imageSix,
    text: "TekVenn - Kurs i bruk av teknologi for seniormusikere og artister",
  },
];

export const images = [
  {
    id: 1,
    title: "TekVenn",
    description:
      "Gratis kurs i grunnleggende ferdigheter innen hverdagsteknologi",
    img: imgOne,
    link: "/entekvenn",
  },
  {
    id: 2,
    title: "Husk å Danse",
    description: "Forebygg, brems og håndter demens gjennom dans",
    img: imgTwos,
    link: "#",
  },
  {
    id: 3,
    title: "ODIS",
    description: "Gratis kurs i kodig og digitale ferdigheter",
    img: imgThrees,
    link: "#",
  },
  {
    id: 4,
    title: "Spilleadferd",
    description:
      "Info/veiledning til ressurspersoner om spilleavhengighet/adferd",
    img: imgFours,
    link: "/domestic-help2",
  },
  {
    id: 5,
    title: `Digitalt introprogram`,
    description: "Kurs i digitale ferdigheter for nyankomne flytninger",
    img: imgFive,
    link: "#",
  },
  {
    id: 6,
    title: `Vold i nære relasjoner`,
    description: "Verktøy for arbeid mot vold i minoritetsbefolkningen",
    img: imgSix,
    link: "/domestic-help",
  },
];

export const contactData = [
  {
    id: 1,
    name: "England",
    helpline: "Refuge’s National Domestic Abuse Helpline",
    phone: "0808 2000 247",
    link: "Online live chat Web form",
  },
  {
    id: 2,
    name: "Northern Ireland",
    helpline: "Domestic and Sexual Abuse Helpline",
    phone: "0808 802 1414",
    link: "Online live chat help@dsahelpline.org",
  },
  {
    id: 3,
    name: "Scotland",
    helpline: "Domestic Abuse and Forced Marriage Helpline",
    phone: "0800 027 1234",
    link: "Online live chat helpline@sdafmh.org.uk",
  },
  {
    id: 4,
    name: "Wales",
    helpline: "Live Fear Free",
    phone: "0808 80 10 800",
    link: "Online live chatText info@livefearfreehelpline.wales",
  },
  {
    id: 5,
    name: "UK-wide",
    helpline:
      "The Men’s Advice Line run by Respect is a confidential helpline specifically for male victims.",
    phone: "0808 801 0327",
    link: "info@mensadviceline.org.uk",
  },
];

export const modalData = [
  {
    id: 1,
    title: "Mistenker du vold mot barn?",
    description:
      "Ta kontakt med politiet hvis du mistenker at barn eller ungdom er vitne til vold eller blir utsatt for vold og trusler.",
    img: vectorOne,
  },
  {
    id: 2,
    title: "Er du utsatt for vold eller kjenner noen som er det?",
    description:
      "Du må kontakte politiet hvis du blir skadet, truet, er redd for å bli utsatt for vold, eller frykter for livet ditt. Hva er vold i nære relasjoner?",
    img: vectorFive,
  },
  {
    id: 3,
    title: "Tvangsekteskap, kjønnslemlesting og streng sosial kontroll",
    description:
      "Står du eller noen du kjenner i fare for å bli utsett for tvangsekteskap, kjønnslemlesting eller negativ sosial kontroll? ",
    img: vectorThree,
  },
  {
    id: 4,
    title: "Vold mot eldre",
    description:
      "Er du utsatt for vold, eller kjenner du eldre som er utsatt for vold eller trusler? Kontakt politiet for hjelp.",
    img: vectorTwo,
  },
  {
    id: 5,
    title: "Voldsutsatt med oppholdstillatelse",
    description:
      "Er du mishandlet og har opphold i Norge på grunn av familiegjenforening? Oppholdstillatelse på humanitært grunnlag. Bistand fra advokat.",
    img: vectorFour,
  },
  {
    id: 6,
    title: "Utsetter du noen for vold og trusler?",
    description:
      "Har du problemer med å kontrollere sinnet ditt, eller utsetter du andre for vold? Du trenger også hjelp.",
    img: vectorSix,
  },
];

export const languages = [
  {
    id: 1,
    name: "English",
    code: "en",
  },
  {
    id: 2,
    name: "Norsk",
    code: "no",
  },
  {
    id: 3,
    name: "French",
    code: "fr",
  },
  {
    id: 4,
    name: "Swahili",
    code: "sw",
  },
  {
    id: 5,
    name: "Lingala",
    code: "li",
  },
];

export const entekVennData = [
  {
    id:1,
    title: "entekvenn"
  },
  {
    id:2,
    title: "entekvenn"
  },
  {
    id:3,
    title: "entekvenn"
  },
  {
    id:4,
    title: "entekvenn"
  },
  {
    id:5,
    title: "entekvenn"
  },
  {
    id:6,
    title: "entekvenn"
  },
  {
    id:7,
    title: "entekvenn"
  },
  {
    id:8,
    title: "entekvenn"
  },
  {
    id:9,
    title: "entekvenn"
  },
  {
    id:10,
    title: "entekvenn"
  },
]
