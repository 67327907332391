import React from "react"
import "./style.css"
function Card(props){
    const {title,link,imageURL,visitNow}=props.data
    console.log(props)
    return(<div className="ya-card">
        <div className="ya-card-img">
            <img src={imageURL} alt="card-image-placeholder"/>
        </div>
        <div className="ya-card-content">
        <h1>{title}</h1>
        </div>
        <div className="ya-card-link">
          <a href={link} target="__blank">{visitNow}</a>
        </div>
    </div>)
}


export default Card;