import {
  ContentContainer,
  CardOne,
  CardTwo,
  CardThree,
  CardFour,
  CardFive,
  CardSix,
  Title,
  SubTitle,
  MainContent,
  List,
  Item,
  NavLink,
} from "./ModalContentStyles.js";
import { useTranslation } from "react-i18next";

function ModalContent({ id }) {
  const { t } = useTranslation();
  return (
    <ContentContainer>
      {id === 1 ? (
        <CardOne>
          <Title>{t("guidanceSupport.popup_contents.modal1.item_one")}</Title>
          <MainContent>
            <List>
              <Item>{t("guidanceSupport.popup_contents.modal1.item_two")}</Item>
              <Item>
                {t("guidanceSupport.popup_contents.modal1.item_three")}
              </Item>
              <Item>
                {t("guidanceSupport.popup_contents.modal1.item_four")}
              </Item>
              <Item>
                <NavLink
                  href="https://bekymringsmelding.fiks.ks.no/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal1.item_five")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://www.116111.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal1.item_six")}
                </NavLink>
              </Item>
              <Item>
                <NavLink
                  href="https://www.bufdir.no/vold/Vold_og_overgrep_mot_barn_og_unge/Mistenker_du_at_et_barn_er_utsatt_for_vold/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal1.item_seven")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://barnevernvakten.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal1.item_eight")}
                </NavLink>
              </Item>
              <Item>
                <NavLink
                  href="https://min.helsekompetanse.no/course/view.php?id=149"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal1.item_nine")}
                </NavLink>
              </Item>
              <Item>
                <NavLink
                  href="https://dinutvei.no/alle-hjelpetilbud/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal1.item_ten")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://www.oslokrisesenter.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal1.item_eleven")}
                </NavLink>
              </Item>
            </List>
          </MainContent>
        </CardOne>
      ) : id === 2 ? (
        <CardTwo>
          <Title>{t("guidanceSupport.popup_contents.modal2.item_one")}</Title>
          <MainContent>
            <List>
              <Item>{t("guidanceSupport.popup_contents.modal2.item_two")}</Item>
              <Item>
                {t("guidanceSupport.popup_contents.modal2.item_three")}
              </Item>
              <Item>
                <NavLink
                  href="https://dinutvei.no/alle-hjelpetilbud/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal2.item_four")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://www.oslokrisesenter.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal2.item_five")}
                </NavLink>
              </Item>
            </List>
          </MainContent>
        </CardTwo>
      ) : id === 3 ? (
        <CardThree>
          <Title>{t("guidanceSupport.popup_contents.modal3.item_one")}</Title>
          <MainContent>
            <List>
              <Item>{t("guidanceSupport.popup_contents.modal3.item_two")}</Item>
              <Item>
                {t("guidanceSupport.popup_contents.modal3.item_three")}
              </Item>
              <Item>
                {t("guidanceSupport.popup_contents.modal3.item_four")}
              </Item>
              <Item>
                <NavLink
                  href="https://dinutvei.no/alle-hjelpetilbud/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal3.item_five")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://www.oslokrisesenter.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal3.item_six")}
                </NavLink>
              </Item>
            </List>
          </MainContent>
        </CardThree>
      ) : id === 4 ? (
        <CardFour>
          <Title>{t("guidanceSupport.popup_contents.modal4.item_one")}</Title>
          <MainContent>
            <List>
              <Item>{t("guidanceSupport.popup_contents.modal4.item_two")}</Item>
              <Item>
                {t("guidanceSupport.popup_contents.modal4.item_three")}
              </Item>
              <Item>
                {t("guidanceSupport.popup_contents.modal4.item_four")}
              </Item>
              <Item>
                <NavLink
                  href="https://dinutvei.no/alle-hjelpetilbud/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal4.item_five")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://www.oslokrisesenter.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal4.item_six")}
                </NavLink>
              </Item>
            </List>
          </MainContent>
        </CardFour>
      ) : id === 5 ? (
        <CardFive>
          <Title>{t("guidanceSupport.popup_contents.modal5.item_one")}</Title>
          <MainContent>
            <List>
              <Item>{t("guidanceSupport.popup_contents.modal5.item_two")}</Item>
            </List>
            <Item>
              <NavLink
                href="https://dinutvei.no/alle-hjelpetilbud/"
                target="_blank"
              >
                {t("guidanceSupport.popup_contents.modal5.item_three")}
              </NavLink>
            </Item>
            <Item>
              <NavLink href="https://www.oslokrisesenter.no/" target="_blank">
                {t("guidanceSupport.popup_contents.modal5.item_four")}
              </NavLink>
            </Item>
            <Item>
              <NavLink href="http://rosa-help.no/om-rosa/" target="_blank">
                {t("guidanceSupport.popup_contents.modal5.item_five")}
              </NavLink>
            </Item>
          </MainContent>
        </CardFive>
      ) : (
        <CardSix>
          <Title>{t("guidanceSupport.popup_contents.modal6.item_one")}</Title>
          <MainContent>
            <SubTitle>
              {t("guidanceSupport.popup_contents.modal6.item_two")}
            </SubTitle>
            <List>
              <Item>
                <NavLink
                  href="https://dinutvei.no/hjelpetilbud"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal6.item_three")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="http://atv-stiftelsen.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal6.item_four")}
                </NavLink>
              </Item>
              <Item>
                <NavLink
                  href="https://www.bufdir.no/Familie/Finn_familievernkonhrefr/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal6.item_five")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://reform.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal6.item_six")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="http://tryggere.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal6.item_seven")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="http://www.sinnemestring.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal6.item_eight")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="http://www.littsint.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal6.item_nine")}
                </NavLink>
              </Item>
              <Item>
                <NavLink
                  href="https://dinutvei.no/alle-hjelpetilbud/"
                  target="_blank"
                >
                  {t("guidanceSupport.popup_contents.modal6.item_ten")}
                </NavLink>
              </Item>
              <Item>
                <NavLink href="https://www.oslokrisesenter.no/" target="_blank">
                  {t("guidanceSupport.popup_contents.modal6.item_eleven")}
                </NavLink>
              </Item>
            </List>
          </MainContent>
        </CardSix>
      )}
    </ContentContainer>
  );
}

export default ModalContent;
