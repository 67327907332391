import React from "react";
import {
  NavigationContainer,
  Icon,
  CloseIcon,
  MobileNavWrapper,
  MobileNavMenu,
  BrandLogo,
} from "./MobileNavStyles";
import brand from "../../images/10.png";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { NavHashLink } from "react-router-hash-link";
const useStyles = makeStyles((theme) => ({
  linkStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    listStyle: "none",
    fontSize: "1.5rem",
    transition: "0.3s ease-in-out",
    color: "#fff",
    cursor: "pointer",

    "&:hover": {
      color: "rgba(0, 0, 0, 0.9)",
      transition: "0.3s ease-in-out",
    },
  },
}));

const MobileNavbar = ({ toggle, isOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <NavigationContainer isOpen={isOpen}>
      <NavHashLink
        to="/#home"
        scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
        className={classes.linkStyles}
      >
        <BrandLogo src={brand} alt="brand" onClick={toggle} />
      </NavHashLink>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <MobileNavWrapper>
        <MobileNavMenu>
          <NavHashLink
            to="/#home"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
            onClick={toggle}
          >
            {t("menu.home")}
          </NavHashLink>
          <NavHashLink
            to="/#about_us"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
            onClick={toggle}
          >
            {t("menu.who_are_we")}
          </NavHashLink>
          <NavHashLink
            to="/#footer"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
            onClick={toggle}
          >
            {t("menu.contact")}
          </NavHashLink>
          <NavHashLink
            to="/#vision"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            className={classes.linkStyles}
            onClick={toggle}
          >
            {t("menu.vision")}
          </NavHashLink>
          <LanguageSelector
            style={{
              textTransform: "capitalize",
              marginRight: "10px",
              fontSize: "1.5rem",
              color: "#fff",
            }}
          />
        </MobileNavMenu>
      </MobileNavWrapper>
    </NavigationContainer>
  );
};

export default MobileNavbar;
