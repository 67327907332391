import React,{useReducer,useEffect} from "react";
import ChatbotContext from "./context";
import ChatbotReducer from "./reducer";
import Data from "./data.js"

const ChatbotState = (props)=>{
    const initialState= {
        history:[
        {
            type:"only_btn",
            buttons:[{
                text:"French",
                action:"ln"
            },
            {
                text:"Lingala",
                action:"ln"
            },
            {
                text:"Swahili",
                action:"ln"

            }
            ]
        }
        ],
        currentLn:"",
        french:[],
        swahili:[],
        lingala:[]        
    }

    const [state,dispatch]=useReducer(ChatbotReducer,initialState);

    const NextNode=(item)=>{
        const data= Data.french;
        let currentNode=data.filter(node=>{
            return node.node===item;
        });

        console.log(item)
        if(item===1){
            clearChat()
        }
        dispatch({
            type:"UPDATE_HISTORY",
            payload:currentNode[0]
        })

    }


    // useEffect(()=>{
    //     // LoadData()
    //     LoadData("French")

    // },[])

    
    const clearChat=()=>{

        dispatch({
            type:"CLEAR_CHAT",
            payload:true
        })
       

    }


    const LoadData=(item)=>{

        state.history=[]

        if(item==="French"){

            dispatch({
                type:"LOAD_FRENCH",
                payload:Data.french
            });
            dispatch({
                type:"UPDATE_HISTORY",
                payload:Data.french[0]
            })
        }

            
        if(item==="Lingala"){
            dispatch({
                type:"LOAD_LINGALA",
                payload:Data.lingala
            });

            dispatch({
                type:"UPDATE_HISTORY",
                payload:Data.lingala[0]
            })

        }

        if(item==="Swahili"){

            dispatch({
                type:"UPDATE_HISTORY",
                payload:Data.swahili[0]
            })

            dispatch({
                type:"LOAD_SWAHILI",
                payload:Data.swahili
            });
        }
    

    }




   

    return(<ChatbotContext.Provider 
    value={{state,NextNode,LoadData,clearChat}}>
         {props.children}
    </ChatbotContext.Provider>)

}

export default ChatbotState;