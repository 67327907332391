import styled from 'styled-components'

export const HomeContainer = styled.div`
width:100%;
` 
export const Title = styled.h1`
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.02rem;
  color: #000;
  margin: 0;
  /* line-height: 126.9%; */

  @media screen and (max-width: 1024px) {
    font-size: 40px;
  }
  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 512px) {
    font-size: 30px;
  }
`;