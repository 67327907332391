import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  color: blue;
  text-align:center;
  box-sizing: border-box;
  margin:0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* font-family: "Open Sans", sans-serif;  */
  /* font-family: "Volkhov", serif; */
`;
