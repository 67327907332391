import HomeContent from "../../components/HomeContent/HomeContent";
import NewsLetter from "../../components/NewsLetter/NewsLetter";
import Footer from "../../components/Footer/Footer";
import ChatWidget from "../../bot/components/chatWidget";
import { useTranslation } from "react-i18next";
import DomesticHelp2Content from "./components/DomesticHelp2Content/DomesticHelp2Content";

function DomesticHelp2() {
  const { t } = useTranslation();
  return (
    <>
      <ChatWidget />
      <HomeContent title={t("hero_title")} subTitle={t("hero_subtitle")} />
      <DomesticHelp2Content />
      <NewsLetter />
      <Footer />
    </>
  );
}

export default DomesticHelp2;
