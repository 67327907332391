import React from "react";
import {
  HomeTextContainer,
  Title,
  Dash,
  SubTitle,
  FillShape,
  SquareShape,
  RoundShape,
} from "./HomeTextStyles";
import fill from "../../images/13.png";
import square from "../../images/12.png";
import round from "../../images/11.png";
import { motion } from "framer-motion";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Typewriter from 'typewriter-effect';
const HomeText = ({ title, subTitle }) => {
  return (
    <HomeTextContainer>
      <Fade left duration={2000}>
        <Title>
          <span>
            <Typewriter
              options={{
                strings: [title],
                autoStart: true,
                pauseFor: 600000000
              }}
            />
          </span>
        </Title>
      </Fade>
      <Dash></Dash>
      <Fade left duration={3000}>
        <SubTitle>{subTitle}</SubTitle>
      </Fade>
      <Bounce top cascade duration={4000}>
        <FillShape src={fill} alt="fill" />
      </Bounce>
      <SquareShape
        as={motion.img}
        animate={{ rotate: 360 }}
        transition={{
          ease: "easeOut",
          duration: 2,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        src={square}
        alt="square"
      />
      <Bounce top cascade duration={5000}>
        <RoundShape src={round} alt="round" />
      </Bounce>
    </HomeTextContainer>
  );
};

export default HomeText;
