import "./index.css";
import Chat from "./chat";
import { useContext } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import ChatBotContext from "../context/context";
import RenderChatWidget from "./renderChatWidget";

function ChatWidget() {
  const [isChatOpen, setChatOpen] = useState(false);

  const toggle = () => {
    if (isChatOpen === true) {
      setChatOpen(false);
    }
    if (isChatOpen === false) {
      setChatOpen(true);
    }
  };
  return (
    <>
      <div className="chat_widgets">
        {isChatOpen ? (
          <div className="chatScreen">
            <div className="header" onClick={toggle}>
              <p>Mandari Bot</p>
              <span className="closeBtn">X</span>
            </div>
            <Chat />
          </div>
        ) : (
          <RenderChatWidget toggle={toggle} />
        )}
      </div>
    </>
  );
}

export default ChatWidget;
